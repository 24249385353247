const ALPHABET = "abcdefghijklmnopqrstuvwxyz".split("");

type KeyboardProps = {
  onLetterClick: (letter: string) => void;
  onBackSpace?: () => void;
};

export function Keyboard({ onLetterClick, onBackSpace }: KeyboardProps) {
  return (
    <div className="mx-auto  flex flex-wrap justify-center max-w-sm">
      {ALPHABET.map((letter) => (
        <button
          key={letter}
          onClick={() => onLetterClick(letter)}
          className="px-4 py-2 m-2 rounded-lg shadow-lg bg-white hover:bg-gray-200 focus:outline-none focus:shadow-outline active:bg-gray-300 text-gray-800 font-bold"
        >
          {letter}
        </button>
      ))}
      <button className="px-4 py-2 m-2" onClick={onBackSpace}>⌫</button>
    </div>
  );
}
