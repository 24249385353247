import type { ActionArgs } from "@remix-run/cloudflare";
import { redirect } from "@remix-run/cloudflare";
import { Form } from "@remix-run/react";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { Keyboard } from "~/components/Keyboard";
import db from "~/db";

export async function action({ request }: ActionArgs) {
  const body = await request.formData();
  const word = body.get("secretWord");
  const docRef = await addDoc(collection(db, "games"), {
    word,
    state: "playing",
    wrongGuesses: [],
    goodGuesses: [],
  });
  return redirect(`/${docRef.id}/watch`);
}

export default function New() {
  const [secretWord, setSecretWord] = useState<string>("");

  return (
    <div className="p-4">
      <h1 className="text-7xl mb-4">Le Pendu 😵‍💫</h1>

      <h1>{secretWord || "_"}</h1>

      <Keyboard
        onLetterClick={(letter) => setSecretWord(secretWord + letter)}
        onBackSpace={() => setSecretWord(secretWord.slice(0, -1))}
      />

      <Form method="post">
        <input name="secretWord" type="hidden" value={secretWord} />

        <button
          type="submit"
          disabled={!secretWord}
          className="rounded-lg px-4 py-2 bg-amber-400 text-xl mt-8"
        >
          Nouvelle partie
        </button>
      </Form>
    </div>
  );
}
